import React, {ReactNode} from "react";

import styles from './index.module.css';
import {NavBar, Space} from "antd-mobile";
import {useNavigate} from "react-router-dom";
import { UserOutline, MoreOutline, CloseOutline, LeftOutline } from 'antd-mobile-icons'


interface Props {
    title?: string|undefined,
    navIcon?: AppBarNavIcon,
    navNode?: ReactNode|undefined,
    actionNode?: ReactNode|Array<ReactNode>|undefined,
    subtitle?: string|undefined,
    onTapNav?: () => void | undefined,
}


enum AppBarNavIcon {
    Empty = 0,
    Back = 1,
    Close = 2,
    User = 3,
}

/***********************************************************************************************************************
 * 标题栏
 */

const AppBar = (props: Props) : React.JSX.Element => {
    const {
        title,
        navIcon,
        navNode,
        actionNode,
        subtitle,
        onTapNav,
    } = props;

    const navigate = useNavigate();

    // 导航按钮点击事件
    const componentOnBack = onTapNav ?? (() => {
        navigate(-1);
    })

    // 导航按钮
    let componentBacArrow : ReactNode|undefined = null;
    switch (navIcon) {
        case AppBarNavIcon.Back:
        case undefined: {
            componentBacArrow = <LeftOutline />;
            break;
        }
        case AppBarNavIcon.Close: {
            componentBacArrow = <CloseOutline />;
            break;
        }
        case AppBarNavIcon.User: {
            componentBacArrow = <UserOutline />;
            break;
        }
    }

    // 右侧内容
    let navBarRightNode : ReactNode|undefined = null;
    if (Array.isArray(actionNode)) {
        // 如果是 ReactNode
        navBarRightNode = (
            <Space>{actionNode}</Space>
        )
    } else if (actionNode) {
        navBarRightNode = actionNode;
    }

    return (
        <NavBar
            className={styles.container}
            backArrow={componentBacArrow}
            onBack={componentOnBack}
            right={navBarRightNode}
            // right={<div>aaa</div>}
        >
            <span>{title}</span>
        </NavBar>
    );
}

/***********************************************************************************************************************
 * 标题栏选项
 */

interface AppBarActionProp {
    isEnable?: boolean,
    color?: string,
    isVertical?: boolean,
    onClick?: () => void,
    space?: string,
    width?: string,
    height?: string,

    icon?: string|ReactNode,
    iconSize?: string,
    iconWidth?: string,
    iconHeight?: string,

    text?: string,
    textColor?: string,
    textSize?: number,
}

const AppBarAction = (prop: AppBarActionProp) : React.JSX.Element => {
    const realIsEnable = prop.isEnable ?? true;
    return (
        <Space
            align={'center'}
            onClick={() => {
                if (realIsEnable && prop.onClick) {
                    prop.onClick();
                }
            }}
            style={{
                opacity: realIsEnable ? 1 : 0.5,
                transitionDuration: '300ms',
                flexDirection: prop.isVertical ? 'column' : 'row',
                width: prop.width,
                height: prop.height,
                "--gap-horizontal": prop.space ?? '6px',
                "--gap-vertical": prop.space ?? '4px',
            }}
        >
            {/* 图标 - url */}
            {
                typeof prop.icon === 'string' ? (
                    <img
                        src={prop.icon}
                        alt={prop.text}
                        style={{
                            width: prop.iconWidth ?? prop.iconSize,
                            height: prop.iconHeight ?? prop.iconSize,
                        }}
                    />
                ) : null
            }

            {/* 图标 - ReactNode */}
            {
                typeof prop.icon !== 'string' ? prop.icon : null
            }

            {/* 文本 */}
            {
                prop.text ? (
                    <span
                        style={{
                            color: prop.textColor ?? prop.color ?? '#000',
                            fontSize: prop.textSize,
                        }}
                    >
                        { prop.text }
                    </span>
                ) : null
            }

        </Space>
    )
}


export default AppBar;
export {
    AppBarNavIcon,
    AppBarAction,
}
