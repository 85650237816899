import {createBrowserRouter} from "react-router-dom";
import Index from "../page/Index";
import Login from "../page/Login";
import UpdateServerAddress from "../page/UpdateServerAddress";
import BillBookAddEdit from "../page/BillBookAddEdit";
import BillRecordAddEdit from "../page/BillRecordAddEdit";
import BillTagManage from "../page/BillTagManage";
import BillTagAddEdit from "../page/BillTagAddEdit";


const RouteIndex = '/'
const RouteLogin = '/login'
const RouteUpdateServerAddress = '/login/update_server_address'
const RouteBillBookEdit = '/billbook/add_edit/:id'
const RouteBillBookAdd = '/billbook/add_edit'
const RouteRecordBookEdit = '/billrecord/add_edit/:id'
const RouteRecordBookAdd = '/billrecord/add_edit'
const RouteBillTagManage = '/billtag/manage'
const RouteBillTagAdd = '/billtag/add_edit'
const RouteBillTagEdit = '/billtag/add_edit/:id'


const OBRouter = createBrowserRouter([
    {
        path: RouteIndex,
        element: <Index />
    },
    {
        path: RouteLogin,
        element: <Login />
    },
    {
        path: RouteUpdateServerAddress,
        element: <UpdateServerAddress />,
    },
    {
        path: RouteBillBookAdd,
        element: <BillBookAddEdit />
    },
    {
        path: RouteBillBookEdit,
        element: <BillBookAddEdit />
    },
    {
        path: RouteRecordBookAdd,
        element: <BillRecordAddEdit />
    },
    {
        path: RouteRecordBookEdit,
        element: <BillRecordAddEdit />
    },
    {
        path: RouteBillTagManage,
        element: <BillTagManage />
    },
    {
        path: RouteBillTagAdd,
        element: <BillTagAddEdit />
    },
    {
        path: RouteBillTagEdit,
        element: <BillTagAddEdit />
    },
])

export default OBRouter;

export {
    RouteIndex,
    RouteLogin,
    RouteUpdateServerAddress,
    RouteBillBookAdd,
    RouteBillBookEdit,
    RouteBillTagManage,
}
