import {NavigateFunction, useNavigate} from "react-router-dom";
import {RouteBillBookAdd, RouteBillTagManage, RouteIndex, RouteLogin} from "./route";

interface AddBillRecordProp {
    defaultBillBookID?: number
}

const Router = {

    goBack : (navigate: NavigateFunction) => {
        if (window.history.length > 1) {
            navigate(-1)
        } else {
            Router.replaceIndex(navigate)
        }
    },

    replaceLogin : (navigate: NavigateFunction) => {
        navigate(RouteLogin, { replace: true })
    },

    replaceIndex : (navigate: NavigateFunction) => {
        navigate(RouteIndex, { replace: true })
    },

    pushAddBillBook : (navigate: NavigateFunction) => {
        navigate(RouteBillBookAdd)
    },

    pushEditBillBook : (navigate: NavigateFunction, id: number) => {
        navigate(`/billbook/add_edit/${id}`)
    },

    pushAddBillRecord : (navigate: NavigateFunction, prop?: AddBillRecordProp) => {
        const params = new URLSearchParams()
        if (prop?.defaultBillBookID) {
            params.append('billbook_id', prop.defaultBillBookID.toString())
        }
        navigate(`/billrecord/add_edit?${params.toString()}`)
    },

    pushEditBillRecord: (navigate: NavigateFunction, id: number) => {
        navigate(`/billrecord/add_edit/${id}`)
    },

    pushBillTagManager: (navigate: NavigateFunction) => {
        navigate(RouteBillTagManage)
    },

    pushBillTagAdd: (navigate: NavigateFunction, parentID: number = 0) => {
        navigate(`/billtag/add_edit?parent_id=${parentID}`)
    },

    pushBillTagEdit: (navigate: NavigateFunction, id: number) => {
        navigate(`/billtag/add_edit/${id}`)
    },

}

export default Router;
