import {NetworkBillTag, NetworkBillTagList} from "../network/network_billtag";
import KVStorage from "../kv/kv_storage";
import {KVCacheBillTag, KVCacheBillTagUpdateTime} from "../kv/kv_cache_def";
import Theme from "../../definition/theme";

interface BillTag {
    id: number,
    name: string,
    sort: number,
    color: string,
    children: BillTag[] | undefined,
}

class _BillTagCache {

    public static instance = new _BillTagCache();

    private constructor() {
        this.loadAllTag()
    }

    /******************************************************************************************************************* 缓存内容 */
    cache = new Map<number, NetworkBillTag>() // 缓存内容，id -> tag
    cacheTree = new Map<number, Array<number>>() // 缓存结构, id -> [id1, id2, id3]
    cacheHasInit = false // 缓存是否已经初始化过
    cacheIsRefreshing = false // 是否正在刷新缓存
    cacheUpdateTime = 0 // 缓存更新时间
    cacheExpiredTime = 1000 * 60 * 60 * 24 // 缓存过期时间（24小时），超过这段时间后会静默更新

    /******************************************************************************************************************* 缓存加载 */

    private parseFromResponse(tagList: NetworkBillTag[]) {
        const tmpCache = new Map<number, NetworkBillTag>()
        const tmpCacheTree = new Map<number, Array<number>>()

        for (let i = 0; i < tagList.length; i++) {
            const tag = tagList[i]
            tmpCache.set(tag.id, tag)
            if (tag.parent_id) {
                // 是子节点
                if (!tmpCacheTree.has(tag.parent_id)) {
                    tmpCacheTree.set(tag.parent_id, [])
                }
                tmpCacheTree.get(tag.parent_id)?.push(tag.id)
            } else {
                // 是父节点
                if (!tmpCacheTree.has(tag.id)) {
                    tmpCacheTree.set(tag.id, [])
                }
            }
        }

        this.cache = tmpCache
        this.cacheTree = tmpCacheTree
        this.cacheHasInit = true
    }

    /******************************************************************************************************************* 缓存加载 */

    private loadAllTag(isForce: boolean = false): Promise<void> {
        /* 缓存已在加载中，则延迟800ms再检查 */
        if (this.cacheIsRefreshing) {
            return new Promise(resolve => {
                setTimeout(() => resolve(0), 800)
            }).then(v => {
                if (this.cacheIsRefreshing) {
                    return Promise.reject('缓存正在更新中，请稍后重试')
                }
                if (this.cacheHasInit) {
                    return Promise.resolve()
                }
                return Promise.reject()
            })
        }
        /* 缓存没在加载中，则开始加载 */
        return Promise.resolve().then(() => {
            /* 本地加载 */
            if (!this.cacheHasInit) {
                // 读取缓存
                try {
                    const tmpCache = KVStorage.get(KVCacheBillTag)
                    const tmpCacheUpdateTime = KVStorage.get(KVCacheBillTagUpdateTime)
                    this.cacheUpdateTime = tmpCacheUpdateTime
                    const tmpCacheList = JSON.parse(tmpCache) as NetworkBillTag[]
                    this.parseFromResponse(tmpCacheList)
                } catch (e) {
                    console.log(`【BillTagCache】缓存不存在或者解析失败, error=${e}`)
                }
            }
        }).then(() => {
            /* 网络加载 */
            const tmpCacheUpdateTime = KVStorage.get(KVCacheBillTagUpdateTime)
            const tmpIsCacheValid = Date.now() - tmpCacheUpdateTime < this.cacheExpiredTime
            const tmpActionLoadFromNetwork = () => NetworkBillTagList().then(tagList => {
                this.parseFromResponse(tagList)
                KVStorage.set(KVCacheBillTag, JSON.stringify(tagList))
                KVStorage.set(KVCacheBillTagUpdateTime, Date.now())
            })
            if (this.cacheHasInit && !isForce) {
                // 缓存可用，则使用缓存
                if (tmpIsCacheValid) {
                    // 缓存可用，则直接返回
                    return Promise.resolve()
                } else {
                    // 缓存过期，则静默更新
                    tmpActionLoadFromNetwork()
                    return Promise.resolve()
                }
            } else {
                // 缓存不可用，则等待网络加载回来
                return tmpActionLoadFromNetwork()
            }
        })
    }

    public loadTags(isForce: boolean = false): Promise<BillTag[]> {
        return this.loadAllTag(isForce).then(() => {
            // 获得 根节点
            return Array.from(this.cacheTree.keys()).map((tagGroupID) => {
                return this.cache.get(tagGroupID)
            }).filter((tagGroup) => {
                return tagGroup !== undefined
            }).map(tagNotUndefined => {
                return tagNotUndefined as NetworkBillTag
            }).sort((tagA, tagB) => {
                return tagA.sort - tagB.sort
            }).map<BillTag>(tag => {
                return {
                    id: tag.id,
                    name: tag.name,
                    sort: tag.sort,
                    color: tag.color,
                    children: [],
                }
            })
        }).then((tagList) => {
            // 补充根节点的子节点
            for (let tagGroup of tagList) {
                // 取得二级标签ID列表
                const childTagIDArray = this.cacheTree.get(tagGroup.id)
                if (childTagIDArray === undefined) {
                    continue
                }
                for (let childTagID of childTagIDArray) {
                    const childTag = this.cache.get(childTagID)
                    if (childTag === undefined) {
                        continue
                    }
                    if (tagGroup.children === undefined) {
                        tagGroup.children = []
                    }
                    tagGroup.children.push({
                        id: childTag.id,
                        name: childTag.name,
                        sort: childTag.sort,
                        color: childTag.color,
                        children: undefined,
                    })
                }
            }
            return tagList
        })
    }

    public getTagInCache(tagID: number): BillTag|undefined {
        const tag = this.cache.get(tagID)
        if (tag === undefined) {
            return undefined
        }

        const tagChildList: BillTag[] = []
        const tagChildIDArray = this.cacheTree.get(tagID)
        if (tagChildIDArray) {
            for (let tagChildID of tagChildIDArray) {
                const tagChild = this.getTagInCache(tagChildID)
                if (tagChild) {
                    tagChildList.push(tagChild)
                }
            }
        }

        return {
            id: tag.id,
            name: tag.name,
            sort: tag.sort,
            color: tag.color,
            children: tagChildList,
        }
    }


    public loadTag(tagID: number): Promise<BillTag|undefined> {
        return Promise.resolve().then(async () => {
            if (!this.cacheHasInit) {
                await this.loadTags();
            }
            return Promise.resolve()
        }).then(() => {
            return this.getTagInCache(tagID)
        })
    }

    public getParentTag(tagID: number): Promise<BillTag|undefined> {
        return this.loadAllTag().then(() => {
            const childTag = this.cache.get(tagID)
            if (childTag === undefined) {
                throw new Error('标签不存在')
            }
            const parentTagID = childTag.parent_id
            if (parentTagID === undefined) {
                throw new Error('没有父标签')
            }
            return this.loadTag(parentTagID)
        }).catch(err => {
            return Promise.resolve(undefined)
        })
    }

    public getTagColor(tagID: number): string {
        const tag = this.cache.get(tagID)
        if (tag === undefined) { // 没有找到标签，则使用默认
            return Theme.themeColor
        }
        if (tag.color) { // 有颜色，则使用颜色
            return '#' + tag.color
        }
        const parentTag = this.cache.get(tag.parent_id)
        if (parentTag === undefined) { // 没有找到父标签，则使用默认
            return Theme.themeColor
        }
        if (parentTag.color) { // 有颜色，则使用颜色
            return '#' + parentTag.color
        }
        return Theme.themeColor

    }

}

const BillTagCache = _BillTagCache.instance;

export default BillTagCache
export type {
    BillTag,
}
